import React from 'react'
import NavBar from "../components/NavBar";
import { Container } from 'react-bootstrap';


function Unauthorized() {
  return (
    <>
    <NavBar/>
    <Container style={{height:"100vh" , width:"100vw"}}  className='d-flex flex-column justify-content-center align-items-center '>
        <h1>Unauthorized access!</h1>
        <h3>Please contact your Admin</h3>
        <br />
        <h3><a href="/">Go to login</a></h3>
    </Container>
    </>
  )
}

export default Unauthorized
import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import CryptoJS from "crypto-js";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
function AffiliateProfile() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [show, setShow] = useState(false);
  const [affiliate, setAffiliate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAffiliate({ ...affiliate, [name]: value });
  };
  const handleClose = () => {
    setShow(false);
    setAffiliate({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
    });
  };
  const handleShow = () => setShow(true);
  const [profile, setProfile] = useState({});
  const [saving, setSaving] = useState(false);
  const { aff_id } = useParams();

  async function getAffiliateProfile() {
    try {
      setloader(true);
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/affiliate_profile`,
        method: "POST",
        data: {
          aff_id: aff_id,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response.data.error) {
        setProfile(response.data.result);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setloader(false);
    }
  }

  useEffect(() => {
    getAffiliateProfile();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 py-4">Affiliate Profile</h2>
          </Col>
        </Row>
        <Row>
          {loader ? <Loader/> :<Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter Name"
                    required
                    name="first_name"
                    value={profile?.first_name}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter Name"
                    required
                    name="last_name"
                    value={profile?.last_name}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="email"
                    placeholder="Email"
                    required
                    name="email"
                    value={profile?.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>

                <Form.Group className="mb-3" controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="tel"
                    placeholder="Phone"
                    required
                    name="phone"
                    value={profile?.phone}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formAffiliateCode">
                  <Form.Label>Affiliate Code</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Affiliate Code"
                    required
                    name="af_code"
                    value={profile?.af_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    as="textarea"
                    // rows={3}
                    placeholder="Address"
                    required
                    name="address"
                    value={profile?.address}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Country"
                    required
                    name="country"
                    value={profile?.country}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formZip">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Zip Code"
                    required
                    name="zip_code"
                    value={profile?.zip_code}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>}
        </Row>
        <Row>
          <Col sm={12}>
            <h2 className="fw-500 fs-24 py-4">Subscribers</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Customer Id</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {profile?.subscribers?.map(({user}, index) => (
                <tr
                  key={user?.email}
                >
                  <td>{user?.user_id}</td>
                  <td>
                    {user?.first_name} {user?.last_name}
                  </td>
                  <td>{user?.email}</td>
                  <td>{user?.phone}</td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      {/* <Modal
        size=""
        contentClassName="rounded-4"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-1" closeButton>
          <Modal.Title className="fw-700 fs-30 mx-4">{affiliate?.aff_id ? 'Edit' : 'Add'} Affiliate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSaveAffiliate} className="mx-4 mt-4">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter First Name"
                    required
                    name="first_name"
                    value={affiliate.first_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter Last Name"
                    required
                    name="last_name"
                    value={affiliate.last_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="email"
                placeholder="Enter Email"
                required
                name="email"
                value={affiliate.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="tel"
                placeholder="Enter Phone"
                required
                name="phone"
                value={affiliate.phone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup className="rounded-pill overflow-clip border border-black">
                <Form.Control
                  minLength={8}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                  title="Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character."
                  className="border-0"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  required = {!affiliate?.aff_id}
                  name="password"
                  value={affiliate.password}
                  onChange={handleInputChange}
                />
                <InputGroup.Text
                  className="border-0 cursor-pointer"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    </span>
                  )}
                </InputGroup.Text>
              </InputGroup>
              {affiliate?.aff_id && (
                <small className="text-danger">
                  <i>
                  Leave the password field empty if you don't want to change it.
                  </i>
                </small>
              )}
            </Form.Group>
            
            <button disabled={saving} type="submit" className="grd-btn rounded-pill w-100">
              {
                saving ? <Loader /> : 'Save'
              }
            </button>
          </Form>
        </Modal.Body>
      </Modal> */}
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default AffiliateProfile;

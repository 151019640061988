import { Modal } from "react-bootstrap";
import React, { useState } from "react";

function FailureModal({ open, removeModal, successFailureMessage }) {
  return (
    <div>
      <Modal
        show={open === "dismissible"}
        size="md"
        onClose={() => removeModal()}
        zIndex="1000"
        centered
      >
        <Modal.Body className="p-0 rounded-[8px]">
          <div className="flex justify-center items-center bg-[#ED400A] py-3">
            <svg className="icon text-[white] text-[50px]">
              <use href="#icon_faield"></use>
            </svg>
          </div>
          <div className="p-4 py-8">
            <h3 className="text-center font-bold text-[26px]">Sorry</h3>
            <p className="text-center font-bold text-[20px] text-[#7D7D7D]">
              {successFailureMessage}
            </p>
            <div className="pt-4 flex justify-center">
              <button
                type="button"
                className="flex w-[180px] items-center text-center justify-center py-2 px-[20px] rounded-[20px] font-semibold bg-[#ED400A] text-white hover:bg-[#C83E12]"
                onClick={() => removeModal()}
              >
                TRY AGAIN
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FailureModal;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useCommonApi from "../hooks/common-api";
import CryptoJS from "crypto-js";

function AdminUserProfile() {
  const navigate = useNavigate();
  const [editCancel, setEditCancel] = useState(false);
  const [profileSettings, setprofileSettings] = useState({});
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const profilePicRef = useRef(null);
  const [updating, setupdating] = useState(false);
  const [loader, setloader] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const [showPass, setShowPass] = useState(false);

  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  // console.log(profileSettings, "settings...");
  const handleChange = (e) => {
    let { id, value } = e.target;
    if (id === "is_admin") {
      value = e.target.checked;
    }
    // console.log(id, value);
    setprofileSettings({ ...profileSettings, [id]: value });
  };
  const uploadIconFn = async () => {
    try {
      let formdata = new FormData();

      formdata.append("vsn_file", rawFile);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/upload/files/adminuserprofiles`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        timeout: 10000,
        data: formdata,
      });

      if (!response.data.error) {
        return {
          error: false,
          data: response.data.file_path,
        };
      } else {
        return {
          error: true,
          data: response.data.message,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error.message,
      };
    }
  };
  const submitProfileDetails = async (e) => {
    e?.preventDefault();
    setupdating(true);
    try {
      let profile_photo = "";
      if (rawFile) {
        let response = await uploadIconFn();
        console.log(response);
        if (!response.error) {
          profile_photo = response.data;
        } else {
          setopenFailureModal(true);
          setsuccessFailureMessage(response.message);
          setupdating(false);
          return;
        }
      }
      let data = {...profileSettings};
      delete data.role_name;
      if (rawFile) {
        data.profile_photo = profile_photo;
      }
      if(data?.password){
        const cipherText = CryptoJS.AES.encrypt(
          data?.password,
          process.env.REACT_APP_SECRET
        );
        data.password = cipherText.toString();
      }else{
        delete data.password;
      }

      if(data?.is_admin){
        data.role_id = null;
      }
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/update_admin_profile`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: data,
      });
      setupdating(false);
      console.log(response, "response");
      if (!response.data.error) {
        setEditCancel(false);
        setsuccessFailureMessage(response.data.message);
        setopenSuccessModal("dismissible");
      } else {
        setsuccessFailureMessage(response.data.message);
        setopenFailureModal("dismissible");
      }
    } catch (error) {
      setupdating(false);
      if (error && error.response.status === 401) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_data");
        navigate("/");
      }
      setsuccessFailureMessage(error.response.data.message);
      setopenFailureModal("dismissible");
      console.log(error);
    }
  };
  const handleChangeFileFun = (e) => {
    if (!editCancel) {
      return;
    }
    let file = e.target.files[0];

    setrawFile(file);
    e.target.files = null;
  };
  async function getProfile() {
    try {
      setloader(true);
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/admin_profile`,
        method: "POST",
        data: {
          admin_user_id: JSON.parse(localStorage.getItem("admin_user_data"))
            ?.admin_user_id,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response.data.error) {
        let details = response?.data?.result;
        let profile_photo = details?.profile_photo;
        // console.log(details,"details...")
        if (profile_photo) {
          setpreviewUrl(profile_photo);
        } else {
          setpreviewUrl(null);
        }
        setprofileSettings(details);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setloader(false);
    }
  }
  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    if (!rawFile) {
      // setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  // console.log(previewUrl, "preview url");
  return (
    <>
      <div className="d-flex">
        <h2 className="fw-700 fs-30 pt-4">My Profile</h2>
        <div className="ms-auto pt-4">
          {editCancel ? (
            <button
              type="button"
              className="underline font-semibold text-[20px] hover:text-[#1A71A7]"
              onClick={() => setEditCancel(false)}
            >
              Cancel
            </button>
          ) : (
            <button
              type="button"
              className="underline font-semibold text-[20px] hover:text-[#1A71A7]"
              onClick={() => setEditCancel(true)}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center pb-4 mt-8">
            <div className="flex flex-column items-center">
              <div>
                <form
                  id="file-upload-form"
                  className="uploader  w-[120px] h-[120px] "
                  style={{ width: "120px", height: "120px" }}
                >
                  <input
                    id="file-upload"
                    type="file"
                    name="fileUpload"
                    accept="image/*"
                    disabled={!editCancel}
                    onChange={(e) => {
                      if (!editCancel) {
                        return;
                      }
                      handleChangeFileFun(e);
                    }}
                  />

                  <label
                    className="flex justify-center items-center cursor-pointer"
                    htmlFor="file-upload"
                    id="file-drag"
                    style={{
                      width: "120px",
                      height: "120px",
                      background: previewUrl && "none",
                    }}
                    onClick={() => {
                      if (!editCancel) {
                        return;
                      }
                    }}
                    ref={profilePicRef}
                  >
                    <div id="start">
                      {previewUrl ? (
                        <div className="relative">
                          <div className="rounded-[50%] w-[120px] h-[120px] flex items-center justify-center overflow-hidden">
                            <img
                              src={previewUrl}
                              className="w-full object-cover"
                              alt="..."
                            />
                          </div>
                          {editCancel && (
                            <button
                              type="button"
                              className="absolute w-[25px] h-[25px] bg-[#5DE56A] right-[5px] bottom-[5px] rounded-[50%] text-[white] hover:text-[red]"
                              onClick={() => profilePicRef?.current?.click()}
                            >
                              <svg className="icon">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          )}
                        </div>
                      ) : (
                        <>
                          <svg className="icon text-[24px]">
                            <use href="#icon_upload"></use>
                          </svg>
                          <div id="notimage" className="text-sm">
                            Please select an image
                          </div>
                        </>
                      )}
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                    </div>
                  </label>
                </form>
              </div>
            </div>
          </div>

          <form className="w-full" onSubmit={submitProfileDetails}>
            <div className="flex flex-wrap -mx-6 mb-6">
              <div className="w-full md:w-1/2 px-6 mb-6 md:mb-0">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="first_name"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your First Name"
                  disabled={!editCancel}
                  value={profileSettings?.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="last_name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Last Name"
                  disabled={!editCancel}
                  value={profileSettings?.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="phone"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Phone Number"
                  disabled={!editCancel}
                  value={profileSettings?.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="last-name"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Email Id"
                  disabled
                  value={profileSettings?.email}
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="address1"
                >
                  Address 1
                </label>
                <input
                  type="text"
                  id="address1"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Address 1"
                  disabled={!editCancel}
                  value={profileSettings?.address1}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="address2"
                >
                  Address 2
                </label>
                <input
                  type="text"
                  id="address2"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Address 2"
                  disabled={!editCancel}
                  value={profileSettings?.address2}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="city"
                >
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your City"
                  disabled={!editCancel}
                  value={profileSettings?.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="state"
                >
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your State"
                  disabled={!editCancel}
                  value={profileSettings?.state}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="nationality"
                >
                  Nationality
                </label>
                <input
                  type="text"
                  id="nationality"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Nationality"
                  disabled={!editCancel}
                  value={profileSettings?.nationality}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="pin_code"
                >
                  Pin Code
                </label>
                <input
                  type="text"
                  id="pin_code"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Pin Code"
                  disabled={!editCancel}
                  value={profileSettings?.pin_code}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="role_name"
                >
                  Role
                </label>
                <input
                  type="text"
                  id="role_name"
                  className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                  placeholder="Enter Your Role"
                  disabled
                  value={profileSettings?.role_name}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              
              <div className="w-full md:w-1/2 px-6">
                <label
                  className="block mb-2 font-semibold text-[22px]"
                  for="password"
                >
                  Password
                </label>
                <small className="text-danger">
                  <i>
                    Leave the password field empty if you don't want to change
                    it.
                  </i>
                </small>

                <div class="position-relative">
                  <input
                    type={showPass ? "text" : "password"}
                    onChange={handleChange}
                    value={profileSettings?.password}
                    className="w-full rounded-full mb-[20px] font-[400] text-[20px] border border-[#E9EBF0] bg-[#FAFBFC]"
                    placeholder="Password"
                    autoComplete="off"
                  />
                  <button
                    type="button"
                    className="vision"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? (
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="flex pb-4 ">
              <div className="ms-auto">
                {editCancel ? (
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="py-2 w-[140px] rounded-[20px] font-semibold ring-[1px] ring-[#2A2A2A] ring-inset hover:ring-[none] bg-gradient-to-r hover:from-[#1A71A7] hover:to-[#9D3784] hover:text-white hover:transition hover:delay-75 me-[20px]"
                      onClick={async() => {
                       await getProfile()
                        setEditCancel(false);
                      }}
                    >
                      Discard
                    </button>

                    <button
                      type="submit"
                      className="flex items-center text-center justify-center py-2 w-[140px] rounded-[20px] font-semibold bg-gradient-to-r from-[#1A71A7] to-[#9D3784] text-white  hover:from-[#9D3784] hover:to-[#1A71A7]"
                    >
                      <span>
                        {updating ? (
                          <div className="flex items-center">
                            <div>Saving</div>
                          </div>
                        ) : (
                          <>Save</>
                        )}
                      </span>
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </form>
        </>
      )}
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default AdminUserProfile;

import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function FaqComponent({ faqs, setFaqs, addFaq }) {
  const handleFaqChange = (index, field, value) => {
    const newFaqs = [...faqs];
    newFaqs[index][field] = value;
    setFaqs(newFaqs);
  };

  const removeFaq = (index) => {
    setFaqs(faqs.filter((_, i) => i !== index));
  };
  return (
    <div className="mt-2">
      {faqs.map((faq, index) => (
        <div className="faq" key={index}>
          <Form.Group className="mb-1 question">
            <Row>
              <Col xs={11}>
                <Form.Label>Question {index + 1}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={faq.question}
                  onChange={(e) =>
                    handleFaqChange(index, "question", e.target.value)
                  }
                />
              </Col>
              <Col xs={1} className="d-flex align-items-center">
                <Button
                  variant="success"
                  className="me-2"
                  onClick={() => addFaq(index)}
                >
                  +
                </Button>
                <Button variant="danger" onClick={() => removeFaq(index)}>
                  X
                </Button>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3 content">
            <Form.Label>Answer {index + 1}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={faq.content}
              onChange={(e) =>
                handleFaqChange(index, "content", e.target.value)
              }
            />
          </Form.Group>
        </div>
      ))}
    </div>
  );
}

export default FaqComponent;

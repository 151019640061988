import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import moment from "moment";
import CryptoJS from "crypto-js";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
import useCommonApi from "../hooks/common-api";
function AdminUserList() {
  const navigate = useNavigate();
  const {state} = useLocation()
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [rolesList, setRolesList] = useState([])
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role_id: "",
    is_admin: false
  });

  const [showPassword, setShowPassword] = useState(false);

  const {getRolesList } = useCommonApi()
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value,checked } = event.target;
    setUser({ ...user, [name]: name == 'is_admin' ? checked : value });
  };
  const handleClose = () => {
    setShow(false);
    setUser({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role_id: "",
      is_admin: false
    });
  };
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [userlist, setuserlist] = useState([]);
  const [saving, setSaving] = useState(false)
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (loader) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore]
  );
 

  async function getuserlistFn(from) {
    try {
      setloader(true);
      if (from == "reload") {
        setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/admin_list`,
        method: "POST",
        data: {
          page: from == "reload" ? 0 : currentPage,
          searchtxt: searchInput,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      sethasMore(false);

      if (!response.data.error) {
        const newList = response.data.result.filter((newList) => {
          return !userlist.some(
            (existingList) =>
              existingList?.admin_user_id === newList?.admin_user_id
          );
        });
        if (from == "reload") {
          setuserlist(response.data.result);
        } else {
          setuserlist([...userlist, ...newList]);
        }
        sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }

  async function handleSaveUser(e) {
    e.preventDefault();
    try {
      setSaving(true);
      let data = {...user};
      if(data?.password){
        const cipherText = CryptoJS.AES.encrypt(
          data?.password,
          process.env.REACT_APP_SECRET
        );
        data.password = cipherText.toString();
      }else{
        delete data.password;
      }

      if(data?.is_admin){
        data.role_id = null;
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/add_edit_user`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });


      if (!response?.data?.error) {
        setopenSuccessModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
        getuserlistFn("reload");
        handleClose();
        setTimeout(() => {
          removeModal();
        }, 3000);
      }else{
        setopenFailureModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setSaving(false);
    }
  }
  useEffect(() => {
    if (currentPage == 0) {
      getuserlistFn("reload");
    } else {
      getuserlistFn();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!searchInput.length) {
      getuserlistFn("reload");
    }
  }, [searchInput]);
useEffect(() => {
  getRolesList(null,setRolesList)
}, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">All Users</h2>
            <div className="d-flex py-4 ">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getuserlistFn("reload");
                }}
                className="d-flex "
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form>
              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn rounded-pill py-2 d-flex align-items-center"
                  onClick={() => handleShow()}
                >
                  Add User
                  <svg className="icon fs-20 ms-1">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>#</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Edit</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {userlist?.map((item, index) => (
                <tr
                  ref={
                    userlist?.length == index + 1 ? lastBookElementRef : tempRef
                  }
                  key={item?.email}
                >
                  <td>{index + 1}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.role_name}</td>
                  {/* <td>{item?.author_name}</td> */}
                  {/* <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role_id="switch"
                          id="flexSwitch1"
                          onChange={(e) => {
                            setuserlist((prev) =>
                              prev?.map((i) => {
                                if (i?.id === item?.id) {
                                  i.status = e.target.checked;
                                }
                                return i;
                              })
                            );
                            featureStatusBlog({
                              id: item?.id,
                              status: e.target.checked ? "1" : "0",
                              act: "status",
                            });
                          }}
                          checked={item?.status}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role_id="switch"
                          id="flexSwitch1"
                          onChange={(e) => {
                            setuserlist((prev) =>
                              prev?.map((i) => {
                                if (i?.id === item?.id) {
                                  i.featured = e.target.checked;
                                }
                                return i;
                              })
                            );
                            featureStatusBlog({
                              id: item?.id,
                              featured: e.target.checked ? "1" : "0",
                              act: "featured",
                            });
                          }}
                          checked={item?.featured}
                        />
                      </div>
                    </td>*/}
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setUser({
                          first_name: item?.first_name,
                          last_name: item?.last_name,
                          email: item?.email,
                          password: "",
                          role_id: item?.role_id,
                          admin_user_id: item?.admin_user_id,
                          is_admin: item?.is_admin
                        })
                        handleShow();
                      }}
                    >
                      <button type="button" className="arrow-btn ">
                        <svg className="icon fs-24">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      <Modal
        size=""
        contentClassName="rounded-4"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-1" closeButton>
          <Modal.Title className="fw-700 fs-30 mx-4">{user?.admin_user_id ? 'Edit' : 'Add'} User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSaveUser} className="mx-4 mt-4">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter First Name"
                    required
                    name="first_name"
                    value={user.first_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter Last Name"
                    required
                    name="last_name"
                    value={user.last_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="email"
                placeholder="Enter Email"
                required
                name="email"
                value={user.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup className="rounded-pill overflow-clip border border-black">
                <Form.Control
                  minLength={8}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                  title="Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character."
                  className="border-0"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  required = {!user?.admin_user_id}
                  name="password"
                  value={user.password}
                  onChange={handleInputChange}
                />
                <InputGroup.Text
                  className="border-0 cursor-pointer"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    </span>
                  )}
                </InputGroup.Text>
              </InputGroup>
              {/* below password there will be small note in red color */}
              {user?.admin_user_id && (
                <small className="text-danger">
                  <i>
                  Leave the password field empty if you don't want to change it.
                  </i>
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formIsAdmin">
              <Form.Check
                className="rounded-pill"
                name="is_admin"
                checked={user.is_admin}
                onChange={handleInputChange}
                label="Is Admin"
                />
              {/* <Form.Label>Is Admin</Form.Label> */}
              </Form.Group>
              {!user?.is_admin && <Form.Group className="mb-3" controlId="formRole">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  className="rounded-pill"
                  required
                  name="role_id"
                  value={user.role_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Role</option>
                  {
                    rolesList?.map((item, index) => (
                      <option key={item?.role_id + item?.role_name} value={item?.role_id}>
                        {item?.role_name}
                      </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>}
            
            <button disabled={saving} type="submit" className="grd-btn rounded-pill w-100">
              {
                saving ? <Loader /> : 'Save'
              }
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default AdminUserList;

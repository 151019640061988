import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import CryptoJS from "crypto-js";
import SuccessModal from "../components/SuccessModal";
import FailureModal from "../components/FailureModal";
function AffiliateList() {
  const navigate = useNavigate();
  const {state} = useLocation()
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [openSuccessModal, setopenSuccessModal] = useState(undefined);
  const [openFailureModal, setopenFailureModal] = useState(undefined);
  const [successFailureMessage, setsuccessFailureMessage] = useState("");
  const removeModal = () => {
    setopenSuccessModal(undefined);
    setopenFailureModal(undefined);
  };
  const [show, setShow] = useState(false);
  const [affiliate, setAffiliate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    zip_code: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAffiliate({ ...affiliate, [name]: value });
  };
  const handleClose = () => {
    setShow(false);
    setAffiliate({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address: "",
      country: "",
      zip_code: "",
      password: "",
    });
  };
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [userlist, setuserlist] = useState([]);
  const [saving, setSaving] = useState(false)
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (loader) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore]
  );
 

  async function getaffiliatelistFn(from) {
    try {
      setloader(true);
      if (from == "reload") {
        setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/affiliate_list`,
        method: "POST",
        data: {
          page: from == "reload" ? 0 : currentPage,
          searchtxt: searchInput,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      sethasMore(false);

      if (!response.data.error) {
        const newList = response.data.result.filter((newList) => {
          return !userlist.some(
            (existingList) =>
              existingList?.aff_id === newList?.aff_id
          );
        });
        if (from == "reload") {
          setuserlist(response.data.result);
        } else {
          setuserlist([...userlist, ...newList]);
        }
        sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }

  async function handleSaveAffiliate(e) {
    e.preventDefault();
    try {
      setSaving(true);
      let data = {...affiliate};
      if(data?.password){
        const cipherText = CryptoJS.AES.encrypt(
          data?.password,
          process.env.REACT_APP_SECRET
        );
        data.password = cipherText.toString();
      }else{
        delete data.password;
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/add_edit_affiliate`,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });


      if (!response?.data?.error) {
        setopenSuccessModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
        getaffiliatelistFn("reload");
        handleClose();
        setTimeout(() => {
          removeModal();
        }, 3000);
      }else{
        setopenFailureModal('dismissible');
        setsuccessFailureMessage(response?.data?.message);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setSaving(false);
    }
  }
  useEffect(() => {
    if (currentPage == 0) {
      getaffiliatelistFn("reload");
    } else {
      getaffiliatelistFn();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!searchInput.length) {
      getaffiliatelistFn("reload");
    }
  }, [searchInput]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">All Affiliates</h2>
            <div className="d-flex py-4 ">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getaffiliatelistFn("reload");
                }}
                className="d-flex "
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form>
              <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn rounded-pill py-2 d-flex align-items-center"
                  onClick={() => handleShow()}
                >
                  Add Affiliate
                  <svg className="icon fs-20 ms-1">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>#</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Country</th>
                <th>Affiliate Code</th>
                <th>Edit</th>
                <th>Profile</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {userlist?.map((item, index) => (
                <tr
                  ref={
                    userlist?.length == index + 1 ? lastBookElementRef : tempRef
                  }
                  key={item?.email}
                >
                  <td>{index + 1}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.country}</td>
                  <td>{item?.af_code}</td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => {
                        setAffiliate({
                          first_name: item?.first_name,
                          last_name: item?.last_name,
                          email: item?.email,
                          phone: item?.phone,
                          password: "",
                          country: item?.country,
                          zip_code: item?.zip_code,
                          address: item?.address,
                          aff_id: item?.aff_id,
                        })
                        handleShow();
                      }}
                    >
                      <button type="button" className="arrow-btn ">
                        <svg className="icon fs-24">
                          <use href="#icon_edit"></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-flex align-items-center"
                      onClick={()=>{
                        navigate('/affiliates/' + item?.aff_id,{
                          state
                        })
                      }}
                    >
                      <button type="button" className="arrow-btn ">
                        <svg className="icon fs-24">
                          <use href="#icon_vision"></use>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      <Modal
        size=""
        contentClassName="rounded-4"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-1" closeButton>
          <Modal.Title className="fw-700 fs-30 mx-4">{affiliate?.aff_id ? 'Edit' : 'Add'} Affiliate</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form onSubmit={handleSaveAffiliate} className="mx-4 mt-4">
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter First Name"
                    required
                    name="first_name"
                    value={affiliate.first_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Enter Last Name"
                    required
                    name="last_name"
                    value={affiliate.last_name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="email"
                placeholder="Enter Email"
                required
                name="email"
                value={affiliate.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="tel"
                placeholder="Enter Phone"
                required
                name="phone"
                value={affiliate.phone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                className="rounded-pill"
                type="text"
                as={"textarea"}
                rows={1}
                placeholder="Enter Address"
                required
                name="address"
                value={affiliate.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Country"
                    required
                    name="country"
                    value={affiliate?.country}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formZip">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    className="rounded-pill"
                    type="text"
                    placeholder="Zip Code"
                    required
                    name="zip_code"
                    value={affiliate?.zip_code}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup className="rounded-pill overflow-clip border border-black">
                <Form.Control
                  minLength={8}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                  title="Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character."
                  className="border-0"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  required = {!affiliate?.aff_id}
                  name="password"
                  value={affiliate.password}
                  onChange={handleInputChange}
                />
                <InputGroup.Text
                  className="border-0 cursor-pointer"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    </span>
                  )}
                </InputGroup.Text>
              </InputGroup>
              {/* below password there will be small note in red color */}
              {affiliate?.aff_id && (
                <small className="text-danger">
                  <i>
                  Leave the password field empty if you don't want to change it.
                  </i>
                </small>
              )}
            </Form.Group>
            
            <button disabled={saving} type="submit" className="grd-btn rounded-pill w-100">
              {
                saving ? <Loader /> : 'Save'
              }
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessModal
        open={openSuccessModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
      <FailureModal
        open={openFailureModal}
        removeModal={removeModal}
        successFailureMessage={successFailureMessage}
      />
    </>
  );
}

export default AffiliateList;

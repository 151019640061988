import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Dashboard() {
  return (
    <>
    <Container fluid>
        <Row>
            <Col sm={12}>
              <h2 className='fw-700 fs-30 py-4'>Dashboard</h2>
            </Col>
        </Row>
        {/* <div className='row row-cols-2 row-cols-md-3 row-cols-lg-3 g-3 pb-4'>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_sales"></use>
                    </svg>
                  </div>
                  <p>Total Sales Members</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>20</h4>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_pricetag"></use>
                    </svg>
                  </div>
                  <p>Total Pricing Members</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>04</h4>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_setting"></use>
                    </svg>
                  </div>
                  <p>Total Operations Members</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>15</h4>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_customer"></use>
                    </svg>
                  </div>
                  <p>Total Customers</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>250</h4>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_container"></use>
                    </svg>
                  </div>
                  <p>Total Containers In-Transit</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>20</h4>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='dash-card'>
              <div className='line'>
                <div className='d-flex align-items-center'>
                  <div className='icon-box me-2'>
                    <svg className="icon fs-18 text-white">
                      <use href="#icon_container"></use>
                    </svg>
                  </div>
                  <p>Total Containers Delivered</p>
                </div>
                <h4 className='fw-700 fs-30 pt-1'>60</h4>
              </div>
            </div>
          </div>
        </div> */}
    </Container>
    </>
  )
}

export default Dashboard
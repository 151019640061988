import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import moment from "moment";
import { Datepicker } from "flowbite-react";

function LeadsList() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  // const [show, setShow] = useState(false);

  // const handleClose = () => {
  //   setShow(false)
  //   setSelectedUser()
  // };
  // const handleShow = (user) => {
  //   if(user?.referral_code){
  //   setShow(true)
  //   setSelectedUser(user)
  // }
  // };
  // const [selectedUser, setSelectedUser] = useState()
  // const [currentPage, setCurrentPage] = useState(0);
  // const [hasMore, sethasMore] = useState(false);
  const [leadslist, setleadslist] = useState([]);

  // const observer = useRef();
  // const tempRef = useRef();
  // const lastBookElementRef = useCallback(
  //   (node) => {
  //     console.log("intersecting");
  //     if (loader) {
  //       return;
  //     }
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setCurrentPage((prev) => prev + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loader, hasMore]
  // );
  // console.log(leadslist);
  const formatDateTime = (dateTimeString, time) => {
    if (!dateTimeString) {
      return "";
    }
    if (time) {
      return moment(dateTimeString).format("D MMMM, YYYY hh:mm A");
    }
    return moment(dateTimeString).format("D MMMM, YYYY");
  };

  async function getleadslistFn(from) {
    try {
      setloader(true);
      if (from == "reload") {
        // setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/contact_lead`,
        method: "POST",
        data: {
          // page: from == "reload" ? 0 : currentPage,
          searchtxt: searchInput ? searchInput : null,
          start_date: fromDate
            ? moment(fromDate).format("YYYY-MM-DD")
            : toDate
            ? moment().format("YYYY-MM-DD")
            : null,
          end_date: toDate
            ? moment(toDate).format("YYYY-MM-DD")
            : fromDate
            ? moment().format("YYYY-MM-DD")
            : null,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      // sethasMore(false);

      if (!response.data.error) {
        // const newList = response.data.result.filter((newList) => {
        //   return !leadslist.some((existingList) => existingList?.user_id === newList?.user_id);
        // });
        // if (from == "reload") {
        setleadslist(response.data?.data);
        // } else {
        //   setleadslist([...leadslist, ...newList]);
        // }
        // sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      // setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }
  useEffect(() => {
    // if (currentPage == 0) {
    //   getleadslistFn("reload");
    // } else {
    getleadslistFn();
    // }
    console.log(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!searchInput.length) {
      getleadslistFn("reload");
    }
  }, [searchInput]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Leads</h2>
            <div className="d-flex py-4 space-x-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getleadslistFn("reload");
                }}
                className="d-flex mt-2"
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form>

              {/* <Datepicker title="From Date" placeholder="From Date"  className="mt-2 no-tbl" value={fromDate}  onSelectedDateChanged={(e)=>setFromDate(moment(e).format('DD MMM YYYY'))} /> */}
              {/* <Datepicker title="From Date" placeholder="From Date"  className="mt-2 no-tbl" value={toDate}  onSelectedDateChanged={e=>setToDate(moment(e).format('DD MMM YYYY'))}/> */}

              <input
                type="date"
                className="mt-2 rounded-pill"
                placeholder="From Date"
                max={toDate || moment().format("YYYY-MM-DD")}
                value={fromDate || "From Date"}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  if (!toDate && e.target.value) {
                    setToDate(moment().format("YYYY-MM-DD"));
                  }
                }}
              />
              <input
                type="date"
                className="mt-2 rounded-pill"
                placeholder="To Date"
                value={toDate || "To Date"}
                min={fromDate || moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setToDate(e.target.value);
                  if (!fromDate && e.target.value) {
                    setFromDate(moment().format("YYYY-MM-DD"));
                  }
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Lead Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>State</th>
                <th>Trial</th>
                <th>Remark</th>
                <th>Page URL</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {leadslist?.map((item, index) => (
                <tr
                  // ref={
                  //   leadslist?.length == index + 1 ? lastBookElementRef : tempRef
                  // }
                  key={item?.email + item?.id}
                >
                  <td>{item?.id}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phone}</td>
                  <td>{item?.city}</td>
                  <td>{item?.state}</td>
                  <td>{item?.trial ? "Yes" : "No"}</td>
                  <td>{item?.remark}</td>
                  <td>
                    <a href={item?.page_url} target="_blank">
                      {item?.page_url}
                    </a>{" "}
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      {/* <Modal size="lg" show={show} onHide={handleClose} centered animation={true}>
        <Modal.Header className="border-0 " closeButton>
        <h5 className="fw-600 fs-20 text-center">
          Reffered Users </h5></Modal.Header>
         
        <Modal.Body className="pt-0">
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Customer Id</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
              {selectedUser?.referral_user?.map((item, index) => (
                  <tr
                  key={item?.user?.email}
                  >
                    <td>{item?.user?.user_id}</td>
                    <td>{item?.user?.first_name} {item?.user?.last_name}</td>
                    <td>{item?.user?.email}</td>
                  </tr>
                ))}
            </table>
            {selectedUser?.referral_user?.length == 0 && <div className="text-center">No Reffered Users</div>}
          </Col>
        </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default LeadsList;

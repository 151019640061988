import { React, useState, useRef, useEffect } from "react";
import { Form, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import CryptoJS from "crypto-js";
import useCommonApi from "../hooks/common-api";

function Login() {
  const navigate = useNavigate();
  const emailInputRef = useRef();
  const passInputRef = useRef();
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
  });
  const [error, seterror] = useState({
    status: false,
    message: "",
  });
  const [accessMenus, setAccessMenus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  async function handleLogin(e) {
    seterror({
      status: false,
      message: "",
    });
    e.preventDefault();
    setIsLoading(true);
    const email = emailInputRef.current.value;
    const pass = passInputRef.current.value;
    try {
      const cipherText = CryptoJS.AES.encrypt(
        pass,
        process.env.REACT_APP_SECRET
      );
      const data = {
        email: email,
        verifytxt: cipherText.toString(),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_SIGNIN_URL}/admin/login`,
        data
      );
      if (response.status == 200) {
        setIsLoading(false);
        if (!response.data.error) {
          localStorage.setItem("admin_user_token", response?.data?.token);
          localStorage.setItem(
            "admin_user_data",
            JSON.stringify(response?.data?.data)
          );
          if (response?.data?.data?.is_admin) {
            navigate("/dashboard", { replace: true,state:response?.data?.data });
          } else {
            switch (
              response?.data?.data?.access_menu?.role_modules?.find(
                (module) => module?.status
              )?.module_key
            ) {
              case "dashboard":
                navigate("/dashboard", { replace: true ,state:response?.data?.data });
                break;
              case "users":
                navigate("/users/admin-list", { replace: true ,state:response?.data?.data });
                break;
              case "blog":
                navigate("/blog/blog-list", { replace: true ,state:response?.data?.data });
                break;
              case "subscribers":
                navigate("/subscribers", { replace: true ,state:response?.data?.data });
                break;
              case "affiliates":
                navigate("/affiliates", { replace: true ,state:response?.data?.data });
                break;
              default:
                navigate("/unauthorized", { replace: true });
                break;
            }
          }

          // navigate("/dashboard", { replace: true });
        }
      } else {
        setIsLoading(false);
        seterror({
          status: true,
          message: response?.data?.message,
        });
        console.log("Error", response?.data?.message);
      }
    } catch (error) {
      seterror({
        status: true,
        message: error?.response?.data?.message,
      });
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="container-fluid signin-box bg-white">
        <Row className="h-100 position-relative">
          <img src="images/Top.svg" className="top-img" alt="..." />
          <img src="images/Bottom.svg" className="bottom-img" alt="..." />
          <Col className="col-12 col-md-7 signin-left d-flex align-items-center justify-content-center bg-gray">
            <div className="img-box">
              <img src="images/admin.png" className="w-100" alt="admin image" />
            </div>
          </Col>
          <Col className="col-12 col-md-5 signin-right p-4">
            <div className="w-100">
              <div className="d-flex justify-content-center pb-4">
                <img
                  src="images/logo.png"
                  className="w-100"
                  alt="...."
                  style={{ maxWidth: "200px", maxHeight: "50px" }}
                />
              </div>
              <h2 className="fw-700 fs-35 py-4">Admin</h2>
              <form className="mb-5" onSubmit={handleLogin}>
                <div className="mb-3">
                  <label
                    for="exampleFormControlInputEmail"
                    className="form-label fw-400 fs-18"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    ref={emailInputRef}
                    className="form-control rounded-pill form-control-lg input-shadow border-0"
                    id="exampleFormControlInputEmail"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleFormControlInputPassword"
                    className="form-label fs-18 fw-400"
                  >
                    Password
                  </label>
                  {/* <input type={showPass ? "text":"password"} ref={passInputRef} className="form-control rounded-pill form-control-lg input-shadow border-0" id="exampleFormControlInputPassword" placeholder="Enter your password"/> */}
                  <div class="position-relative">
                    <input
                      type={showPass ? "text" : "password"}
                      ref={passInputRef}
                      className="form-control rounded-pill form-control-lg input-shadow border-0"
                      placeholder="Input your password"
                      required
                    />
                    <button
                      type="button"
                      className="vision"
                      onClick={() => setShowPass(!showPass)}
                    >
                      {showPass ? (
                        <svg className="icon">
                          <use href="#icon_vision_withoutslash"></use>
                        </svg>
                      ) : (
                        <svg className="icon">
                          <use href="#icon_vision"></use>
                        </svg>
                      )}
                    </button>
                    {/* <i aria-hidden="true">
                  <svg className="icon fs-24">
                    <use href="#icon_key"></use>
                  </svg>
                </i> */}
                  </div>
                </div>

                <div className="text-center text-danger fw-600 fs-20">
                  {error.status && <>{error.message}</>}
                </div>

                <button
                  type="submit"
                  className="my-5 grd-btn rounded-pill fs-20 fw-600 w-100 d-flex text-center"
                >
                  {isLoading ? <Loader /> : <>Login</>}
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;

import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavBar() {
  const navigate = useNavigate()

  return (
    <>
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="#" className='d-flex'>
                    <img src={window.location.origin+'/images/logo.png'} alt='logo' style={{ width: "140px", height: "40px", maxHeight: "52px" }}></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Form className="d-flex ms-auto">
                        <button type='button' className='logout-btn grd-btn py-2'>Logout</button>
                    </Form>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
  )
}

export default NavBar
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useCommonApi = () => {
  const navigate = useNavigate();

  const getBlogListGraphql = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/graphql`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: {
          query: `
            query{
            getblogs{
              id,
              adm_user_id,
              blog_title,
              blog_content,
              blog_image,
              status,
              featured,
              author_name,
              blog_slug,
              blog_meta_title,
              blog_meta_description,
              publish_date,
              category{
                  blog_cat_id,
                  cat_name,
                  cat_icon
              }
              faqs{
                question,
                content
              }
            }
          }
          `,
        },
      });
      console.log(response, "response graph ql");
      // Handle response here
      return response?.data?.data?.getblogs;
    } catch (error) {
      // Handle error here
    }
  };
  const createBlog = async (data) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/blog/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: data,
      });
      if (!response.data.error) {
        return {
          error: false,
          message: response.data.message,
          data: response.data,
        };
      } else {
        return {
          error: true,
          message: response.data.message,
          data: {},
        };
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const updateBlog = async (data) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/blog/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: data,
      });
      if (!response.data.error) {
        return {
          error: false,
          message: response.data.message,
        };
      } else {
        return {
          error: true,
          message: response.data.message,
        };
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const updateCreateFreeAiTool = async (data) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/free_tools/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: data,
      });
      if (!response.data.error) {
        return {
          error: false,
          message: response.data.message,
        };
      } else {
        return {
          error: true,
          message: response.data.message,
        };
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const featureStatusBlog = async (data) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/adminapi/blog/change_status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
        data: data,
      });
      if (!response.data.error) {
        getBlogListGraphql();
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("admin_user_token");
        localStorage.removeItem("admin_user_data");
        navigate("/");
      }
      console.log(error);
    }
  };
  const getRolesList = async (setloader, setRolesList) => {
    try {
      setloader && setloader(true);

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/roles_list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response?.data?.error) {
        setRolesList && setRolesList(response?.data?.result);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setloader && setloader(false);
    }
  };
  const getModuleAccessList = async (setAccessModules) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/access_modules`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      if (!response?.data?.error) {
        setAccessModules &&
          setAccessModules(
            response?.data?.result?.role_modules?.map(
              (module) => module?.module_key
            )
          );
        return response?.data?.result?.role_modules?.map(
          (module) => module?.module_key
        );
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  return {
    getBlogListGraphql,
    createBlog,
    updateBlog,
    featureStatusBlog,
    getRolesList,
    getModuleAccessList,
    updateCreateFreeAiTool,
  };
};
export default useCommonApi;

import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import moment from "moment";
import * as XLSX from "xlsx";

function UserList() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [loader, setloader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSelectedUser();
  };
  const handleShow = (user) => {
    if (user?.referral_code) {
      setShow(true);
      setSelectedUser(user);
    }
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setSelectedUser();
  };
  const handleShow1 = (user) => {
    if (user?.user_settings?.length) {
      setShow1(true);
      setSelectedUser(user);
    }
  };
  const [selectedUser, setSelectedUser] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [userlist, setuserlist] = useState([]);

  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (loader) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loader, hasMore]
  );
  console.log(userlist);
  const formatDateTime = (dateTimeString, time) => {
    if (!dateTimeString) {
      return "";
    }
    if (time) {
      return moment(dateTimeString).format("D MMMM, YYYY hh:mm A");
    }
    return moment(dateTimeString).format("D MMMM, YYYY");
  };

  async function getuserlistFn(from) {
    try {
      setloader(true);
      if (from == "reload") {
        setCurrentPage(0);
      }
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_SIGNIN_URL}/usermanage/user_list`,
        method: "POST",
        data: {
          page: from == "reload" ? 0 : currentPage,
          searchtxt: searchInput,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("admin_user_token"),
        },
      });

      setloader(false);
      sethasMore(false);

      if (!response.data.error) {
        const newList = response.data.result.filter((newList) => {
          return !userlist.some(
            (existingList) => existingList?.user_id === newList?.user_id
          );
        });
        if (from == "reload") {
          setuserlist(response.data.result);
        } else {
          setuserlist([...userlist, ...newList]);
        }
        sethasMore(newList.length > 0);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
      setCurrentPage(0);
    } finally {
      setloader(false);
    }
  }
  const exportToExcel = () => {
    const tableData = userlist.map((item, index) => ({
      "Customer Id": item?.user_id,
      Name: `${item?.first_name} ${item?.last_name}`,
      Email: item?.email,
      Subscription: item?.plan,
      "Signup Date": formatDateTime(item?.createdAt),
      City: item?.city,
      "IP Address": item?.ip_address,
      "IP Country": item?.ip_contry,
      "Last Login At": formatDateTime(item?.last_login_datetime, true),
      "Referral Code": item?.referral_code,
    }));
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscribers");

    // Export to Excel file
    XLSX.writeFile(workbook, "subscribers_data.xlsx");
  };
  useEffect(() => {
    if (currentPage == 0) {
      getuserlistFn("reload");
    } else {
      getuserlistFn();
    }
  }, [currentPage]);

  useEffect(() => {
    if (!searchInput.length) {
      getuserlistFn("reload");
    }
  }, [searchInput]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <h2 className="fw-700 fs-30 pt-4">Subscribers</h2>
            <div className="d-flex py-4">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  getuserlistFn("reload");
                }}
                className="d-flex mt-2"
              >
                <input
                  type="search"
                  className="form-control rounded-pill"
                  id="searchinput"
                  placeholder="Search Name, Email, Phone"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="grd-btn rounded-pill mx-2"
                  disabled={!searchInput}
                >
                  <svg className="icon">
                    <use href="#icon_search"></use>
                  </svg>
                </button>
              </form>
              <div className="ms-auto">
                <button
                  onClick={exportToExcel}
                  className="grd-btn rounded-pill mx-2"
                >
                  Export to Excel
                </button>
              </div>
              {/* <div className="ms-auto">
                <button
                  type="button"
                  className="grd-btn py-2 d-flex align-items-center"
                  onClick={() => {
                    localStorage.setItem(
                      "admin_selected_blog",
                      JSON.stringify({})
                    );
                    navigate("/blog/create/new");
                  }}
                >
                  <svg className="icon fs-20 me-1">
                    <use href="#icon_plus"></use>
                  </svg>
                  Create Blog
                </button>
              </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="normal-tbl">
            <table>
              <tr className="tr-bg">
                <th>Customer Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Subscription</th>
                <th>Signup Date</th>
                <th>City</th>
                <th>IP Address</th>
                <th>IP Country</th>
                <th>Last Login At</th>
                <th>Referral Code</th>
              </tr>
              {loader && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {userlist?.map((item, index) => (
                <tr
                  ref={
                    userlist?.length == index + 1 ? lastBookElementRef : tempRef
                  }
                  key={item?.email}
                >
                  <td>{item?.user_id}</td>
                  <td>
                    {item?.first_name} {item?.last_name}
                  </td>
                  <td>{item?.email}</td>
                  <td>
                    {item?.plan}
                    <svg
                      onClick={() => handleShow1(item)}
                      className="icon ms-2 cursor-pointer"
                    >
                      <use href="#icon_info"></use>
                    </svg>
                  </td>
                  <td>{formatDateTime(item?.createdAt)}</td>
                  <td>{item?.city}</td>
                  <td>{item?.ip_address}</td>
                  <td>{item?.ip_contry}</td>
                  <td>{formatDateTime(item?.last_login_datetime, true)}</td>
                  <td
                    className="cursor-pointer"
                    onClick={() => handleShow(item)}
                  >
                    {item?.referral_code}
                  </td>
                </tr>
              ))}
            </table>
          </Col>
        </Row>
      </Container>
      {/* Modal  */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        animation={true}
      >
        <Modal.Header className="border-0 " closeButton>
          <h5 className="fw-600 fs-20 text-center">Reffered Users </h5>
        </Modal.Header>

        <Modal.Body className="pt-0 overflow-scroll max-h-[85vh]">
          <Row>
            <Col sm={12} className="normal-tbl">
              <table>
                <tr className="tr-bg sticky top-0">
                  <th>Customer Id</th>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
                {selectedUser?.referral_user?.map((item, index) => (
                  <tr key={item?.user?.email}>
                    <td>{item?.user?.user_id}</td>
                    <td>
                      {item?.user?.first_name} {item?.user?.last_name}
                    </td>
                    <td>{item?.user?.email}</td>
                  </tr>
                ))}
              </table>
              {selectedUser?.referral_user?.length == 0 && (
                <div className="text-center">No Reffered Users</div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Modal  */}
      <Modal
        size="lg"
        show={show1}
        onHide={handleClose1}
        centered
        animation={true}
      >
        <Modal.Header className="border-0 " closeButton>
          <h5 className="fw-600 fs-20 text-center">User settings </h5>
        </Modal.Header>

        <Modal.Body className="pt-0 overflow-scroll max-h-[85vh]">
          <Row>
            <Col sm={12} className="normal-tbl">
              <table>
                <tr className="tr-bg sticky top-0">
                  <th>Name</th>
                  <th>Remaining Value</th>
                  <th>Used Value</th>
                </tr>
                {selectedUser?.user_settings?.map((item, index) => (
                  <tr key={item?.key_name}>
                    <td>{item?.key_name}</td>
                    <td>{item?.key_value}</td>
                    <td>{item?.current_value}</td>
                  </tr>
                ))}
              </table>
              {selectedUser?.user_settings?.length == 0 && (
                <div className="text-center">No Users Settings</div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserList;
